.h2-r {
  font-size: 1.5em !important
}

@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px) {
  .h2-r {
    font-size: 1em !important;
  }
}