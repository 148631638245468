.App {
    
}

.cookie-container {
  background-color: #208fb2;
  position: fixed;
  width: 100vw;
  z-index: 10000;
  color: white;
  padding: 16px;
  top: 0;
  left: 0;
}

.inputs>label {
  color: #fff;
}